<template>
    <div class="home">
      <div class="container">
        <div class="group">
          <Top />
            <div class="group_item"  v-for="(item, index) in groupList" :key="index">
                <div class="group_banner"><img :src="item.img" alt=""></div>
                <div class="group_detail">
                  <div class="group_name">{{ item.group_name }}</div>
                  <div class="group_desc">
                    <a :href="item.link">
                      <div class="group_botton">
                        <div>คลิกเพื่อเข้าร่วม</div>
                        <img src="../assets/image/group_button.png" alt="">
                      </div>
                    </a>
                  </div>
                </div>
            </div>
        </div>
      </div>

      <FooterNav :activeIndex="active"/>
      <FooderText />
    </div>
      
  </template>
  
  <script>
    import Top from "../components/Top.vue";
    import FooderText from "../components/FooterText.vue";
    import FooterNav from "../components/FooterNav.vue";
    
  
  export default {
    components: {
      FooderText,
      FooterNav,
      Top
    },
    data() {  
      return {
        active: 2,
        activeTitle: 0,
        groupList:[],
      };
    },
    computed: {

    },
    mounted() {
  
    },
    created() {
      this.getGroupList()
    },
    methods:{

      getGroupList(){
          this.service.post('Home/getGroupList',{})
          .then((res) => {
              this.groupList = res.data.data
          }) 
      },
    }
  };
  </script>
  
  <style scoped lang="less">
      .group_item{
        height: 480px;
        width: 690px;
        border-radius: 20px;
        background: blue;
        margin-bottom: 32px;
        .group_banner{
          height: 370px;
          width: 690px;
        }
        img{
          width: 100%;
          height: 100%;
        }
        .group_detail{
          height: 110px;
          width: 690px;
          background-color: #FFFFFF;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          .group_name{
            font-size: 32px;
            color: #333333;
            width: 410px;
            float: left;
            line-height: 110px;
            padding-left: 20px;
          }
          .group_desc
          {
            width: 260px;
            height: 110px;
            float: left;
            .group_botton{
              width: 250px;
              height: 55px;
              border-radius: 10px;
              margin-top: 28px;
              background:linear-gradient(to right,#357BFF,#37DCF3);
              text-align: center;
              div{
                float: left;
                width: 194px;
                font-size: 24px;
                font-weight: bold;
                line-height: 55px;
                color: #FFFFFF;
                text-align: center;
              }
              img{
                float: left;
                margin-top: 11px;
                width: 36px;
                height: 36px;
              }
            }
            
          }
          
        }
      }
    
  </style>
  
  